var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-space-between"},[_c('h3',{staticClass:"font-weight-bold blue-grey--text mt-2 font font-md font-weight-medium text-uppercase"},[_vm._v(" All Balances ")])])]),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-end"},[_c('v-text-field',{staticClass:"box-shadow-light font font-sm",staticStyle:{"min-width":"400px"},attrs:{"solo":"","rounded":"","placeholder":"Search through transactions using account number ...","flat":"","append-icon":"search","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 mt-1",attrs:{"loading":_vm.pageLoading,"small":"","fab":"","icon":""},on:{"click":function($event){return _vm.$store.dispatch('balances/list', '?page=1&size=30')}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("refresh")])])]}}])},[_c('span',{staticClass:" font font-sm"},[_vm._v("Get latest transactions")])]),_c('v-btn',{staticClass:" mt-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.open({ idx: 'add', status: true })}}},[_c('span',{staticClass:" font font-weight-medium  font-sm"},[_vm._v("Upload Balances")])])],1)]),_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12","md":"12","xl":"12"}},[_c('v-data-table',{staticClass:"font text-capitalize box-shadow-light mx-1 mt-n2",attrs:{"headers":_vm.headers,"items":_vm.balances,"loading":_vm.pageLoading,"item-key":"branch","server-items-length":_vm.paginate.total,"options":_vm.options,"loading-text":"Loading... Please wait","footer-props":{
          itemsPerPageOptions: [30, 40, 50],
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.customerId",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":""}},[(item.customerId)?_c('span',{staticClass:" font-sm font-weight-medium"},[_vm._v(_vm._s(item.customerId.firstName ? item.customerId.firstName : '')+" "+_vm._s(item.customerId.lastName ? item.customerId.lastName : ''))]):_vm._e()])]}},{key:"item.shares",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:" font-sm font-weight-medium"},[_vm._v(_vm._s(_vm._f("currencyFormat")(item.shares,'GHC'))+" ")])]}},{key:"item.susu",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:" font-sm font-weight-medium"},[_vm._v(_vm._s(_vm._f("currencyFormat")(item.susu,'GHC'))+" ")])]}},{key:"item.savings",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:" font-sm font-weight-medium"},[_vm._v(_vm._s(_vm._f("currencyFormat")(item.savings,'GHC'))+" ")])]}},{key:"item.loans",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:" font-sm font-weight-medium"},[_vm._v(_vm._s(_vm._f("currencyFormat")(item.loans,'GHC'))+" ")])]}},{key:"item.investments",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:" font-sm font-weight-medium"},[_vm._v(_vm._s(_vm._f("currencyFormat")(item.investments,'GHC'))+" ")])]}}])})],1)],1),_c('BulkUploadDialog',{attrs:{"state":_vm.dialog,"loading":_vm.isLoading},on:{"actions":_vm.open,"uploadFile":_vm.uploadFile}}),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }